import { template as template_19ca8a913510477c9b2b139db1506552 } from "@ember/template-compiler";
const FKText = template_19ca8a913510477c9b2b139db1506552(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
