import { template as template_9151affd70884965b63577a1427d34ae } from "@ember/template-compiler";
const FKLabel = template_9151affd70884965b63577a1427d34ae(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
