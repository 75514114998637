import { template as template_5265927d6473473e8ddd6aeec49bc9da } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_5265927d6473473e8ddd6aeec49bc9da(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
