import { template as template_b40c4fc1996d40758459fc4038a90060 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b40c4fc1996d40758459fc4038a90060(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
